import React from 'react';
import { useLocation } from 'react-router-dom';
import { api } from '../../../services/api';
import FullLoading from '../../Utils/FullLoading';
import Card from '../../Card';
import ContactSupportButton from '../../Button/ContactSupportButton';
import { sha256 } from 'js-sha256';
import { apiEndPoints } from '../../../utils/urls';
import InvalidDetails from './InvalidDetails';
import fileDownload from 'js-file-download';
import moment from 'moment'

const BulkOrderStatusPage = () => {
  const [incorrectUrlParams, setIncorrectUrlParams] = React.useState(false);
  function useQuery() {
    return new URLSearchParams(useLocation().search + window.location.hash);
  }

  let orderNumber = encodeURI(useQuery().get('order'));
  let orderEmail = useQuery().get('email');
  let orderPhone = useQuery().get('phone');

  const [bulkOrderStatusData, setbulkOrderStatusData] = React.useState({});

  React.useEffect(() => {
    // If orderNumber is not present redirect user to login page
    // If entered details are incorrect show message to user and redirect
    api
      .get(apiEndPoints.bulkOrder.getBulkOrderStatusDetails, {
        params: {
          email: orderEmail || null,
          phone: orderPhone || null,
          order: orderNumber || null,
        },
      })
      .then((response) => {
        setbulkOrderStatusData(response.data);
        if (response?.data?.errors?.order === 'No order has been found') {
          setIncorrectUrlParams(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const exportBulkOrderStatus = () => {
    api
      .get(apiEndPoints.bulkOrder.export, {
        params: {
          bulk_id: bulkOrderStatusData?.id,
          order: bulkOrderStatusData?.bulk_order_name,
          email: bulkOrderStatusData?.bulk_order_email,
        },
        responseType: 'blob', // Isso é importante para receber o CSV como um Blob
      })
      .then((response) => {
        const timestamp = moment().format("MMMM-Do-YYYY-h:mm:ss-a");
        const filename = "bulkorders_" + timestamp + ".csv";
        fileDownload(response.data, filename);
      })
      .catch((error) => {
        console.error('Error exporting the CSV file:', error);
      });
  };



  return (
    <>
      {incorrectUrlParams ? (
        <InvalidDetails />
      ) : (
        <div className="container">
          {bulkOrderStatusData?.bulk_order_name ? (
            <>
              <Card>
                <>
                  <h4 className="text-center">Order Summary</h4>
                  <div>
                    <div className="row">
                      <div className="col fw-bold">Order ID</div>
                      <div className="col">{bulkOrderStatusData?.bulk_order_name}</div>
                    </div>

                    <div className="row">
                      <div className="col fw-bold">Order Phone Number</div>
                      <div className="col">
                        {bulkOrderStatusData?.bulk_order_phone || 'Number not available'}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col fw-bold">Order Email</div>
                      <div className="col">{bulkOrderStatusData?.bulk_order_email}</div>
                    </div>
                  </div>
                </>
              </Card>

              <BulkOrderStatusTable bulkOrderStatusData={bulkOrderStatusData} exportData={exportBulkOrderStatus} />

              <div className="row my-4 d-flex justify-content-center align-items-center">
                <ContactSupportButton />
              </div>
            </>
          ) : (
            <FullLoading />
          )}
        </div>
      )}
    </>
  );
};

export default BulkOrderStatusPage;

const BulkOrderStatusTable = ({ bulkOrderStatusData, exportData }) => {
  return (
    <>
      {bulkOrderStatusData?.bulk_child_orders?.length > 0 && (
        <div className="card border h-100">
          <div className="card-body small p-1">
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <h4 className="mx-2 my-2">Order Details</h4>
              <div>
                <button className={"btn btn-sm btn-outline-secondary"}
                onClick={exportData}>
                  Export</button>
              </div>
            </div>


            <div className="table-responsive p-md-2 p-sm-3 p-xs-4">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Order ID</th>
                    <th scope="col">Email</th>
                    <th scope="col">Order Status</th>
                    <th scope="col">Shipping To</th>
                    <th scope="col">Fulfillment Status</th>
                    <th scope="col">Tracking Link</th>
                    <th scope="col">Tracking Number</th>
                  </tr>
                </thead>
                <tbody>
                  {bulkOrderStatusData.bulk_child_orders.map((bulkOrderChildData, index) => {
                    return (
                      <tr key={bulkOrderChildData.index + 1}>
                        <th scope="row">{index + 1}</th>
                        <th>{bulkOrderChildData.order_name}</th>
                        <th>{bulkOrderChildData.email}</th>
                        <td>{bulkOrderChildData.order_status}</td>
                        <td>{bulkOrderChildData.shipping_name}</td>
                        <td>{bulkOrderChildData.fulfillment_status}</td>
                        <td>
                          <button className="btn btn-secondary btn-sm"
                                  onClick={() =>
                                    window.open(
                                      `/?email=${sha256(bulkOrderChildData.email)}&order=${
                                        bulkOrderChildData.order_name
                                      }`
                                    )
                                  }
                          >
                            Track Status
                          </button>
                        </td>
                        <td>
                          <a
                            href={bulkOrderChildData.tracking_url}
                            target={"_blank"}
                            className="btn btn-sm btn-link text-decoration-none select-text"
                          >
                            {bulkOrderChildData.tracking_number}
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
